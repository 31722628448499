import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple } from "../components"

export default ({ data, pageContext }) => {
  const { poems_title, poems_text, poems_author } = data.airtable.data

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.data.poems_title : null
  const nextTitle = next ? next.data.poems_title : null
  const prevRow = previous ? previous.data.poems_row : null
  const nextRow = next ? next.data.poems_row : null
  const allItemPage = "/leftover/poems/1"
  const oneItemPage = "/leftover/poem/"

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h2>Versek</h2>
          <h3>{poems_title}</h3>

          <h4>{poems_author}</h4>
          <div
            className="info"
            dangerouslySetInnerHTML={{
              __html: poems_text.childMarkdownRemark.html,
            }}
          ></div>
        </div>

        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background: var(--clr-light-10);
  padding-top: 9rem;
  padding-bottom: 0rem;
  min-height: 100vh;
  /*  a flex itt arra szolgál, hogy rövid szöveg esetén is teljes képernyő legyen
   vagyis alul legyen a pager és a footer!
*/
  display: flex;
  flex-direction: column;

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    /*  ez is a teljes képernyő miatt kell a flex-el együtt */
    margin-bottom: auto;
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }

    .info {
      padding-left: 10%;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }
  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      id
      recordId
      data {
        poems_title
        poems_author
        poems_text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
